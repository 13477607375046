import React, { useState, useEffect } from "react"
import * as S from "./styles"

import Select from "react-select"

const SearchBox = ({
  setSearchQuery,
  setMapCenter,
  setMapZoom,
  categories,
  setCategoryQuery,
  showMarkers,
  searchQuery,
  categoryQuery,
  resetMap,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [showOnMobile, setShowOnMobile] = useState(false)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchQuery(searchTerm)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const getOptions = function (categories) {
    const options = []
    options.push({ value: "All", label: "All" })
    categories.map(function (categories) {
      options.push({
        value: categories.name,
        label: categories.name,
      })
    })
    return options
  }
  const options = getOptions(categories)



  return (
    <>
      <S.OpenControl
        active={showOnMobile}
        onClick={() => setShowOnMobile(!showOnMobile)}
      />
      <S.WrapperSearch active={showOnMobile}>
        <S.Form>
          <S.SearchInput
            id="search-input"
            placeholder="Search for name, city or state"
            onChange={e => setSearchTerm(e.target.value.toLowerCase())}
          ></S.SearchInput>
          <S.SearchSelect
            options={options}
            classNamePrefix="react-select"
            placeholder="Category"
            onChange={e => {
              e.value === "All"
                ? setCategoryQuery("")
                : setCategoryQuery(e.value)
            }}
          />
        </S.Form>
        {(searchQuery || categoryQuery) && (
          <>
            <S.VenuesList>
              {showMarkers.map(marker => (
                <S.Venue
                  key={marker.name}
                  onClick={() => {
                    setMapCenter(marker.geolocationPlain)
                    setMapZoom(17)
                    setShowOnMobile(false)
                  }}
                >
                  <span className="material-icons">
                    {
                      categories.filter(
                        category => category.name === marker.category
                      )[0].icon
                    }
                    {console.log(categories.filter(
                      category => category.name === marker.category
                    )[0].icon)}
                  </span>
                  <div>
                    <h4>{marker.name}</h4>
                    <p>
                      {marker.city} &#8226; {marker.state}
                    </p>
                  </div>
                </S.Venue>
              ))}
            </S.VenuesList>
            <S.Reset
              type="button"
              onClick={() => {
                resetMap()
                setShowOnMobile(false)
                document.getElementById("search-input").value = ""
              }}
            >
              Clear Search
            </S.Reset>
          </>
        )}
      </S.WrapperSearch>
    </>
  )
}

export default SearchBox
